import React from 'react';
import { FacebookOutlined, YouTube, Instagram } from '@mui/icons-material';

function App() {
  return (
    <div className="flex flex-col w-screen h-screen items-center">
      <div className='flex items-center m-4 mt-16 font-black'>
        <hr className=' w-24 m-4 rounded-lg' />
        <p className=' text-3xl items-center w-54'><b>FA Production</b></p>
        <hr className=' w-24 m-4 rounded-lg' />
      </div>

      <div className=''>
        <h1 className=' text-2xl items-center mt-2 font-medium tracking-wider xl:text-5xl'><b>UNDER CONSTRUCTION</b></h1>
      </div>
      <img className=' w-full h-80 fixed top-1/2 -translate-y-1/2' src={require("./images/ud.jpg")} alt="" />

      <div className='flex justify-evenly m-5 fixed bottom-0'>
        <a className=' hover:scale-125 duration-300' href="https://www.facebook.com/FAproduction">
            <FacebookOutlined className=' h-10 w-10 m-3' />
        </a>
        <a className=' hover:scale-125 duration-300' href="https://www.youtube.com/@FaProduction">
            <YouTube className=' h-10 w-10 m-3' />
        </a>
        <a className=' hover:scale-125 duration-300' href="https://instagram.com/faproduction?igshid=MzRlODBiNWFlZA==">
            <Instagram className=' h-10 w-10 m-3' />
        </a>
      </div>
      
    </div>
  );
}

export default App;
